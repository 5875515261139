<template>
    <!--<ion-infinite-scroll v-if="paginationData.totalPage > 0 && paginationData.lastPage > paginationData.page" threshold="100px" @ionInfinite="loadMore($event)" >
        <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
        </ion-infinite-scroll-content>
  </ion-infinite-scroll>-->
    <div>
        CommentLazyloadLayout
    </div>
</template>
<script>
export default {
    name: 'CommentLazyloadLayout',
    mixins: [],
    components: {
    },
    props: {
        paginationData: {
            type: Object,
            default: () => {return {
                totalPage: 0,
                lastPage: 0,
                page: 0,
            }}
        },
        setData: {
            type: Function,
            default: () => {},
        },
        getComment: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return{
            
        }
    },
    beforeRouterEnter() {},
    beforeCreate() {},
    created() {
        
    },
    beforeMount() {},
    mounted() {
        
    },
    beforeUpdate() {},
    updated() {},
    beforeRouterLeave() {},
    beforeDestroy() {},
    destroyed() {},
    computed: {
        
    },
    methods: {
        loadMore($event) {
            let page = this.paginationData.page;
            page = page+1;
            this.setData('page', page)
            this.getComment();
            setTimeout(() => {
                if(this.paginationData.totalPage > page) {
                    $event.target.complete();
                }else{
                    $event.target.disabled = true;
                }
            }, 500)
        },
    },
    watch: {},
}
</script>